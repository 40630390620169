import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import Affiliations from "../components/affiliations";
import Approach from "../components/appoach";
import NotificationBanner from "../components/common/notification-banner";
import Contact from "../components/contact";
import Contribution from "../components/contribution";
import Feature from "../components/feature";
import FeatureTrial from "../components/feature-trial";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Mission from "../components/mission";
import Navbar from "../components/navbar";
import Outreach from "../components/outreach";
import Press from "../components/press";
import Quote from "../components/quote";
import Updates from "../components/updates";
import { opendirSync, readFileSync } from "fs";
import matter from "gray-matter";
import MarkdownIt from "markdown-it";
const md = new MarkdownIt({
  html: true,
});

const Home: NextPage = ({ updates }: { updates?: any }) => {
  return (
    <div data-theme="winter">
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* <NotificationBanner /> */}
      <Navbar />
      <main style={{ scrollBehavior: "smooth" }}>
        <Hero />
        <Mission />
        <Approach />
        <FeatureTrial />
        <Contribution />
        <Feature />
        <Updates updates={updates} />
        <Outreach />
        <Quote />
        <Press />
        <Contact />
        <Affiliations />
        {/* <h1 className={styles.title}>
          Welcome to <a href="https://nextjs.org">Next.js!</a>
        </h1>

        <p className={styles.description}>
          Get started by editing{" "}
          <code className={styles.code}>pages/index.tsx</code>
        </p>

        <div className={styles.grid}>
          <a href="https://nextjs.org/docs" className={styles.card}>
            <h2>Documentation &rarr;</h2>
            <p>Find in-depth information about Next.js features and API.</p>
          </a>

          <a href="https://nextjs.org/learn" className={styles.card}>
            <h2>Learn &rarr;</h2>
            <p>Learn about Next.js in an interactive course with quizzes!</p>
          </a>

          <a
            href="https://github.com/vercel/next.js/tree/canary/examples"
            className={styles.card}
          >
            <h2>Examples &rarr;</h2>
            <p>Discover and deploy boilerplate example Next.js projects.</p>
          </a>

          <a
            href="https://vercel.com/new?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
            className={styles.card}
          >
            <h2>Deploy &rarr;</h2>
            <p>
              Instantly deploy your Next.js site to a public URL with Vercel.
            </p>
          </a>
        </div> */}
      </main>

      <Footer />

      {/* <footer>
        <a
          href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Powered by{" "}
          <span>
            <Image src="/vercel.svg" alt="Vercel Logo" width={72} height={16} />
          </span>
        </a>
      </footer> */}
    </div>
  );
};

export async function getStaticProps() {
  // const res = await fetch("https://www.glockfoundation.org/api/local-files");
  // const files = await res.json();
  // console.log(`files:`, files);

  const dir = opendirSync("./updates");
  const data: any = [];
  for await (const file of dir) {
    const content = readFileSync(`./updates/${file.name}`, "utf-8");
    const html = matter(content);
    // console.log(`html:`, html);
    data.push({
      fileName: file.name,
      ...html,
      data: {
        ...html.data,
        date: html.data.date.toString(),
      },
      content: md.render(html.content),
    });
  }

  return {
    props: {
      updates: data
        .map((d: any) => {
          const { orig, ...rest } = d;
          return rest;
        })
        .sort((a: any, b: any) => a.data.order - b.data.order),
    },
  };
}

export default Home;
